import { WaveFile } from 'wavefile';
import { ITrack } from '../services/track.service';

import { IComposer } from '../services/composer.service';
import { IProject } from '../services/project.service';
import { ITag } from '../services/tag.service';
import { ITeam } from '../services/team.service';
import { buildListInfo } from './mapper_LISTINFO';
import { buildIXML } from './mapper_iXML';

/**
 * Converts a Blob object to a Uint8Array object.
 * @param blob The Blob object to convert.
 * @returns A Promise that resolves with the resulting Uint8Array object.
 * @throws An error if the Blob object cannot be converted to a Uint8Array object.
 */
export function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      if (arrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      } else {
        reject(new Error('Failed to convert Blob to Uint8Array.'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read Blob.'));
    };

    reader.readAsArrayBuffer(blob);
  });
}

/**
 * Converts a JSON object to an XML string.
 * @param jsonObj - The JSON object to convert.
 * @returns The XML string representation of the JSON object.
 */
function jsonToXml(jsonObj: any) {
  let xml = '';

  for (let key in jsonObj) {
    if (jsonObj.hasOwnProperty(key)) {
      if (Array.isArray(jsonObj[key])) {
        jsonObj[key].forEach((item: any) => {
          xml += `<${key}>${jsonToXml(item)}</${key}>`;
        });
      } else if (typeof jsonObj[key] === 'object') {
        xml += `<${key}>${jsonToXml(jsonObj[key])}</${key}>`;
      } else {
        xml += `<${key}>${jsonObj[key]}</${key}>`;
      }
    }
  }
  return xml;
}

function cleanWavBuffer(inputBuffer: Uint8Array): Uint8Array {
  // Helper function to read a UInt32 from a Uint8Array at a given offset (Little Endian)
  function readUInt32LE(array: Uint8Array, offset: number): number {
    return (
      array[offset] |
      (array[offset + 1] << 8) |
      (array[offset + 2] << 16) |
      (array[offset + 3] << 24)
    );
  }

  // Helper function to write a UInt32 to a Uint8Array at a given offset (Little Endian)
  function writeUInt32LE(
    array: Uint8Array,
    value: number,
    offset: number
  ): void {
    array[offset] = value & 0xff;
    array[offset + 1] = (value >> 8) & 0xff;
    array[offset + 2] = (value >> 16) & 0xff;
    array[offset + 3] = (value >> 24) & 0xff;
  }

  const chunkId = String.fromCharCode(...inputBuffer.slice(0, 4));
  if (chunkId !== 'RIFF') {
    throw new Error('Invalid WAV buffer');
  }

  const format = String.fromCharCode(...inputBuffer.slice(8, 12));
  if (format !== 'WAVE') {
    throw new Error('Invalid WAV buffer');
  }

  let offset = 12;
  let cleanOffset = 12;

  // Prepare a new Uint8Array for the cleaned WAV data
  const cleanedBuffer = new Uint8Array(inputBuffer.length);

  // Copy the RIFF header
  cleanedBuffer.set(inputBuffer.slice(0, 12), 0);

  // Process all chunks
  while (offset + 8 <= inputBuffer.length) {
    // Ensure we have enough bytes for the chunk header
    const subChunkId = String.fromCharCode(
      ...inputBuffer.slice(offset, offset + 4)
    );
    const subChunkSize = readUInt32LE(inputBuffer, offset + 4);

    if (offset + 8 + subChunkSize > inputBuffer.length) {
      throw new Error('Invalid chunk size or corrupt WAV buffer');
    }

    if (subChunkId === 'fmt ' || subChunkId === 'data') {
      // Copy the chunk header and data
      cleanedBuffer.set(
        inputBuffer.slice(offset, offset + 8 + subChunkSize),
        cleanOffset
      );
      cleanOffset += 8 + subChunkSize;
    }

    // Move to the next chunk
    offset += 8 + subChunkSize;
  }

  // Calculate the new chunk size and update the RIFF header
  const newChunkSize = cleanOffset - 8; // Exclude 'RIFF' and chunkSize fields
  writeUInt32LE(cleanedBuffer, newChunkSize, 4);

  // Return the cleaned buffer as a Uint8Array
  return cleanedBuffer.slice(0, cleanOffset);
}

export function addTagsToWav(
  buffer: Uint8Array,
  track: ITrack,
  project: IProject,
  composers: IComposer[],
  team: ITeam,
  tags: ITag[]
) {
  const cleanBuffer = cleanWavBuffer(buffer);
  const wav = new WaveFile(cleanBuffer);

  // ------- LIST INFO -------
  // map LIST INFO
  const listInfo = buildListInfo(team, project, track, composers, tags);
  // set Tags
  Object.keys(listInfo).forEach((key) =>
    wav.setTag(key, listInfo[key as keyof typeof listInfo])
  );

  // ------- IXML -------
  // map IXML
  const ixmlObj = buildIXML(team, project, track, composers, tags);
  // convert to xml
  const ixmlString = jsonToXml(ixmlObj);
  // set IXML
  wav.setiXML(ixmlString);

  // ------- BEXT -------
  // map bext
  // const bext = buildBEXT(team, project, track, composers, tags);
  // wav.bext = bext;

  // ------- PMX -------
  // add pmx
  // wav.set_PMX()

  // ,"cue ":"\u0001","_PMX":"

  return wav.toBuffer();
}
